import isPast from 'date-fns/isPast';
import isToday from 'date-fns/isToday';

// Hides events which are in the past (for offline usage).
const events = document.querySelector('.events-table');
if (events) {
	const timestamps = events.querySelectorAll('time');
	for (const timestamp of timestamps) {
		const value = timestamp.dateTime.trim();
		const dateValue = value.slice(0, Math.max(0, value.indexOf('T')));
		const date = new Date(dateValue);
		if (isPast(date) && !isToday(date)) {
			timestamp.parentElement?.parentElement?.remove();
		}
	}
}
