const toggleCard = (card: Element, toggle: HTMLAnchorElement) => {
	card.classList.toggle('flipped');

	const currentTab = document.querySelector('.current');
	if (currentTab) {
		currentTab.firstElementChild?.removeAttribute('aria-selected');
		currentTab.classList.remove('current');
	}

	const newTab = toggle.parentElement;
	if (newTab) {
		toggle.setAttribute('aria-selected', 'true');
		newTab.classList.add('current');
	}
};

// Adds flip effect to page elements.
const card = document.querySelector('.flip-card');
if (card) {
	const toggles: NodeListOf<HTMLAnchorElement> =
		document.querySelectorAll('.flip-toggle a');
	for (const toggle of toggles) {
		toggle.addEventListener(
			'click',
			(event: Event) => {
				event.preventDefault();
				toggleCard(card, toggle);
			},
			false,
		);
	}
}
