const currentPageId = document.documentElement.classList.item(0);
const navLinks = document.querySelectorAll('nav li a');
const skipLink: HTMLElement | null = document.querySelector('.skiplink');
const skipLinkHidden =
	!skipLink ||
	(skipLink && skipLink.offsetWidth === 0 && skipLink.offsetHeight === 0);

if (skipLinkHidden) {
	const navigation = document.querySelector('nav');
	if (navigation) {
		navigation.insertAdjacentHTML('beforeend', '<div id="lava"></div>');
	}
}

const lavaEffect = (event: Event) => {
	if (skipLinkHidden && currentPageId) {
		let element = event.target;
		if (/mouseleave|focusout/.test(event.type)) {
			element = document.querySelector(`#nav-${currentPageId}`);
		}

		const lava: HTMLElement | null = document.querySelector('#lava');
		if (lava) {
			lava.style.inlineSize =
				element instanceof HTMLElement
					? `${element.offsetWidth - 30}px`
					: '';
			lava.style.insetInlineStart =
				element instanceof HTMLElement
					? `${element.offsetLeft + 15}px`
					: '';
		}
	}
};

for (const link of navLinks) {
	link.addEventListener('mouseenter', lavaEffect);
	link.addEventListener('mouseleave', lavaEffect);
	link.addEventListener('focusin', lavaEffect);
	link.addEventListener('focusout', lavaEffect);
}

// Initialize lava effect
const firstLink: HTMLAnchorElement | null = document.querySelector(
	'nav li:first-child a',
);
const leaveEvent = new MouseEvent('mouseleave', {
	bubbles: true,
	cancelable: false,
});
if (firstLink) {
	firstLink.dispatchEvent(leaveEvent);
}
