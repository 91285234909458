const activeClass = 'with-nav';
const size = 220;

let startX = 0;
let currentX = 0;
let touch = false;

const skiplink = document.querySelector('.skiplink');
const headerElm: HTMLElement | null = document.querySelector('.header');
const contentElm = document.querySelector('main');

const update = () => {
	if (!touch) {
		return;
	}

	window.requestAnimationFrame(update);
	let translateX = Math.min(0, currentX - startX) + size;
	if (translateX < 0) {
		translateX = 0;
	}

	if (headerElm && contentElm) {
		headerElm.style.transform = `translateX(${translateX}px)`;
		contentElm.style.transform = `translateX(${translateX}px)`;
	}
};

const reset = () => {
	if (headerElm && contentElm) {
		headerElm.style.transform = '';
		contentElm.style.transform = '';
	}
};

const toggle = () => {
	document.documentElement?.classList.toggle(activeClass);
};

if (skiplink) {
	skiplink.addEventListener('click', () => {
		toggle();
	});
}

if (contentElm) {
	contentElm.addEventListener('click', () => {
		if (document.documentElement.classList.contains(activeClass)) {
			reset();
			toggle();
		}
	});
}

// Add swipe events
if ('requestAnimationFrame' in window) {
	document.addEventListener('touchstart', (event: TouchEvent) => {
		if (!document.documentElement.classList.contains(activeClass)) {
			return;
		}

		startX = event.touches[0].pageX;
		currentX = startX;
		touch = true;
		window.requestAnimationFrame(update);
	});

	document.addEventListener('touchmove', (event: TouchEvent) => {
		if (!touch) {
			return;
		}

		currentX = event.touches[0].pageX;
		const translateX = Math.min(0, currentX - startX) + size;
		if (translateX < 0) {
			event.preventDefault();
		}
	});

	document.addEventListener('touchend', () => {
		if (!document.documentElement.classList.contains(activeClass)) {
			return;
		}

		touch = false;
		const translateX = Math.min(0, currentX - startX);
		reset();
		if (translateX < 0) {
			toggle();
		}
	});
}
