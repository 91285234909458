const emptyIsRandom = true;
const search: HTMLInputElement | null = document.querySelector('#game-search');

/**
 * Toggle button style (random/search).
 * @param {String} value Search value
 */
const toggleButton = (value: string) => {
	const button = document.querySelector('#game-search + button');
	if (button) {
		button.classList.toggle('search-text', value !== '');
	}
};

/**
 * Toggle results.
 * @param {Element} result Result element
 * @param {String} value  Search value
 */
const toggleResult = (result: HTMLElement, value = '') => {
	const resultParent = result.parentElement;
	const content = result.textContent?.toLowerCase() ?? '';
	const filter = value.toLowerCase().trim();

	// Show only matching results
	if (resultParent) {
		if (filter === '' || content.includes(filter)) {
			resultParent.classList.remove('hidden');
		} else {
			resultParent.classList.add('hidden');
		}
	}
};

/**
 * Set search in URL.
 * @param {String} value Search value
 */
const setSearchUrl = (value = '') => {
	history.pushState(
		value,
		'',
		value === '' ? '' : ` ?q=${encodeURIComponent(value)}`,
	);
};

/**
 * Update result counters.
 */
const updateCounters = () => {
	// Get all counters
	const counters = document.querySelectorAll('.games-group .count .value');
	// Update all counters
	for (const counter of counters) {
		// Get items
		const group = counter.parentElement?.parentElement?.parentElement;
		if (group) {
			const all = group.querySelectorAll('li');
			const visible = group.querySelectorAll('li:not(.hidden)');
			const hidden = group.querySelectorAll('li.hidden');

			// Either hide empty groups or update counter value
			const count = all.length - hidden.length;
			if (count === 0) {
				group.classList.add('hidden');
			} else {
				group.classList.remove('hidden');
			}

			counter.setAttribute('value', String(count));
			counter.setAttribute(
				'aria-label',
				count === 1 ? `${count} Spiel` : `${count} Spiele`,
			);
			counter.textContent = String(count);

			// Remove existing first/last classes for rounded corners in small screen view
			for (const item of all) {
				item.classList.remove('first', 'last');
			}

			// Add first/last classes for rounded corners in small screen view
			for (const [index, item] of visible.entries()) {
				if (index === 0) {
					item.classList.add('first');
				}

				if (index === visible.length - 1) {
					item.classList.add('last');
				}
			}
		}
	}
};

/**
 * Get search results.
 * @param {string} value Input value
 */
const getResults = (value: string) => {
	const results: NodeListOf<HTMLAnchorElement> =
		document.querySelectorAll('.games-group a');
	for (const result of results) {
		toggleResult(result, value);
	}

	if (emptyIsRandom) {
		toggleButton(value);
	}

	setSearchUrl(value);
	updateCounters();
};

/**
 * Set layout according to window size
 */
const setLayout = () => {
	if (search) {
		search.removeAttribute('results');
		search.setAttribute('placeholder', 'Spielname eingeben');

		if (window.matchMedia('(min-width: 405px)').matches) {
			search.setAttribute(
				'placeholder',
				'Spielname eingeben (leer = Zufall)',
			);
		}

		if (window.matchMedia('(min-width: 590px)').matches) {
			search.setAttribute(
				'placeholder',
				'Spielname eingeben, leer lassen für Zufallsmodus …',
			);
		}

		if (window.matchMedia('(min-width: 720px)').matches) {
			search.setAttribute('results', '5');
		}
	}
};

/**
 * Create a lightbox.
 * @return {HTMLDivElement} lightbox
 */
const createLightBox = () => {
	const lightbox = document.createElement('div');
	lightbox.id = 'lightbox';
	lightbox.addEventListener('click', () => {
		lightbox.remove();
	});
	document.body.append(lightbox);
	return lightbox;
};

// Enlarge pin setups in a lightbox
const pins = document.querySelectorAll('.pin-setup');
if (pins.length > 0) {
	for (const pin of pins) {
		pin.addEventListener('click', () => {
			// Add pin
			const clonedPin = pin.cloneNode(true);
			const lightbox = createLightBox();
			lightbox.append(clonedPin);
		});
	}
}

if (search) {
	search.addEventListener('input', () => {
		getResults(search.value);
	});

	const searchForm = document.querySelector('form');
	if (searchForm) {
		searchForm.addEventListener('submit', (event: Event) => {
			// Prevent search submission due to auto-search feature
			if (search.value !== '') {
				event.preventDefault();
			}

			// Handle offline random searches
			if (search.value === '' && !navigator.onLine) {
				event.preventDefault();
				const link = searchForm.querySelector('a');
				location.href = link?.href ?? '';
			}
		});
	}

	window.addEventListener('resize', () => {
		window.setTimeout(() => {
			setLayout();
		}, 100);
	});

	setLayout();
}
