// Register our service-worker
if ('serviceWorker' in navigator) {
	void navigator.serviceWorker
		.register('/serviceworker.js', {
			scope: '/',
		})
		.then((registration) => {
			registration.addEventListener('updatefound', () => {
				// If updatefound is fired, it means that there's
				// a new service worker being installed.
				const installingWorker = registration.installing;
				console.log('⏳ installing');

				if (installingWorker) {
					// You can listen for changes to the installing service worker's
					// state via installingWorker.onstatechange
					installingWorker.addEventListener(
						'statechange',
						(event) => {
							console.log(
								`✅ ${
									(event.currentTarget as ServiceWorker).state
								}`,
							);
						},
					);
				}
			});
		});

	// Hide random games URL when offline, use actual game URL
	if (!navigator.onLine && location.pathname === '/kegelspiele/zufall') {
		const alternateLink: HTMLLinkElement | null = document.querySelector(
			'link[rel="alternate"][type="text/plain"]',
		);
		const url = alternateLink?.href ?? location.href;
		if (url) {
			history.replaceState({}, '', url);
		}
	}
}

// Enable app mode
if (
	// @ts-expect-error `standalone` is provided in iOS Safari only
	navigator.standalone ||
	window.matchMedia?.('(display-mode: standalone)').matches
) {
	document.documentElement.classList.add('standalone');
}

// Handle refresh button
const refreshButton = document.querySelector('#refresh');
if (refreshButton) {
	refreshButton.addEventListener('click', () => {
		window.location.reload();
	});
}
